import { createApp } from "vue";
//import AppComponent from "./widget/widget.vue";
import QuoteForm from "./widget/quote-form.vue";
import SampleWidget from "./widget/sample-widget.vue";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics"
import "./util/globals";


let app;
if(NODE_APP_INSTANCE == "sample") {
    app = createApp(SampleWidget);
} else {
    app = createApp(QuoteForm);
}

export {app};
export default app;
